import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image, Linking, TouchableOpacity, Button } from 'react-native';

export default function App() {
  const buyOnRozetka = () => {
    Linking.openURL('https://rozetka.com.ua/ua/405293631/p405293631/');
  }

  const buyOnRozetkaBlack = () => {
    Linking.openURL('https://rozetka.com.ua/ua/405293904/p405293904/');
  }

  const buyInCZ = () => {
    Linking.openURL('https://www.mujnuz.cz/svitilny/led-svitilny/nitecore-tini-2-titanium.html');
  }

  return (

    <View style={styles.container}>
      <Text style={styles.title}>Tiko's Wishlist</Text>
  
      <Image source={{uri: 'https://content2.rozetka.com.ua/goods/images/big/382603890.jpg'}} style={{width: 300, height: 300}} />
      <Text style={styles.description}>Ліхтар наключний Nitecore TINI 2 Ti (2xOSRAM P8, 500 люменів, 5 режимів, USB Type-C), титан</Text>
      
      <Button style={styles.cta}
        title="замовити на ROZETKA"
        color="green"
        onPress={buyOnRozetka}
      />
            <Text>хороша ціна за цей на розетці щей титановий корпус</Text>

      <Button
        title="замовити в Чехії"
        color="blue"
        onPress={buyInCZ}
      />

      <Button style={styles.cta}
        title="замовити чорний на ROZETKA"
        color="grey"
        onPress={buyOnRozetkaBlack}
      />
      <Text>а якщо немає титанового - то чорний буде такий же. просто ціна вища чомусью</Text>

      <StatusBar style="auto" />
    </View>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginVertical: 24
  },
  description: {
    fontSize: 16,
    marginVertical: 24,
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
  },
  cta: {
    fontSize: 16,
    marginVertical: 24,
    padding: 24,
  }
});
